import React from 'react'
import HttpsProxyAgent from "https-proxy-agent"
import axios from 'axios'
import hp from './hp'
import config from './config'


const instance = axios.create();

const adminApiUrl = `${config.api_url}/api/admin`

const httpsAgent = new HttpsProxyAgent({host: "localhost", port: "4000"})


instance.interceptors.request.use(async (config) => {
  config.baseURL = adminApiUrl
  config.headers.id = localStorage.getItem('id')
  config.headers.authorization = 'Bearer ' + localStorage.getItem('token')

  // config.httpsAgent = httpsAgent
  // config.proxy.host = 'localhost'
  // config.proxy.port = '4000'

  return config

}, (error) => {
  return Promise.reject(error);
})

instance.interceptors.response.use(async (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log("res", response)
  }
  if (!response.data) {
    return Promise.reject(response);
  }
  return response

}, async (error) => {
  alert(error.response.data.message)
  if (process.env.NODE_ENV === 'development') {
    console.log('api error', error.response)
  }
  if (error.response && error.response.status == 400) {
    hp.logout()
  }

  return Promise.reject(error);
})

export default instance
