import {
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  Typography,
  Button,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/suss-logo.png";
import { api } from "../services";
import hp from "../services/hp";

function Page(props) {
  const [thumbnail_1, setThumbnail1] = useState("");
  const [thumbnail_2, setThumbnail2] = useState("");
  const [thumbnail_3, setThumbnail3] = useState("");
  const [thumbnail_4, setThumbnail4] = useState("");

  useEffect(() => {
    onStart();
  });

  const onStart = async () => {
    if (
      localStorage.getItem("thumbnail_1") &&
      localStorage.getItem("thumbnail_2") &&
      localStorage.getItem("thumbnail_3")
    ) {
      setThumbnail1(localStorage.getItem("thumbnail_1"));
      setThumbnail2(localStorage.getItem("thumbnail_2"));
      setThumbnail3(localStorage.getItem("thumbnail_3"));
      setThumbnail4(localStorage.getItem("thumbnail_4"));
    } else {
      let thumbnail_1 = await api.get(`config/thumbnail_1`);
      thumbnail_1 = thumbnail_1.data.value;
      let thumbnail_2 = await api.get(`config/thumbnail_2`);
      thumbnail_2 = thumbnail_2.data.value;
      let thumbnail_3 = await api.get(`config/thumbnail_3`);
      thumbnail_3 = thumbnail_3.data.value;
      let thumbnail_4 = await api.get(`config/thumbnail_4`);
      thumbnail_4 = thumbnail_4.data.value;

      setThumbnail1(thumbnail_1);
      setThumbnail2(thumbnail_2);
      setThumbnail3(thumbnail_3);
      setThumbnail4(thumbnail_4);
      localStorage.setItem("thumbnail_1", thumbnail_1);
      localStorage.setItem("thumbnail_2", thumbnail_2);
      localStorage.setItem("thumbnail_3", thumbnail_3);
      localStorage.setItem("thumbnail_4", thumbnail_4);
    }
  };

  const onLogout = () => {
    let c = window.confirm("Please confirm to logout");
    if (c) {
      hp.logout();
    }
  };


  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography style={{ flexGrow: 1 }}>
            <b>MacroVic Event Registration System |</b> {hp.isFull() == 1 ? 'Admin' : 'Guess'} Panel
          </Typography>
          {hp.isPermitted(1) &&
            <Link to="/admin/event1" className="all-unset">
              <Button color="inherit">
                <img src={thumbnail_1} />
              </Button>
            </Link>
          }
          {hp.isPermitted(2) &&
            <Link to="/admin/event2" className="all-unset">
              <Button color="inherit">
                <img src={thumbnail_2} />
              </Button>
            </Link>
          }
          {hp.isPermitted(3) &&
            <Link to="/admin/event3" className="all-unset">
              <Button color="inherit">
                <img src={thumbnail_3} />
              </Button>
            </Link>
          }
          {hp.isPermitted(4) &&
            <Link to="/admin/event4" className="all-unset">
              <Button color="inherit">
                <img src={thumbnail_4} />
              </Button>
            </Link>
          }
          {hp.isFull() == "1" ? (
            <Link to="/admin/config" className="all-unset">
              <Button color="inherit">Configurations</Button>
            </Link>
          ) : (
            <></>
          )}
          <Button color="inherit" onClick={onLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {props.children}
    </div>
  );
}

export default Page;
