import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { api } from '../services'
import hp from "../services/hp";

function Page() {

  const [button_1, setButton1] = useState('')
  const [button_2, setButton2] = useState('')
  const [button_3, setButton3] = useState('')
  const [button_4, setButton4] = useState('')

  useEffect(() => {
    onStart()
  })

  const onStart = async () => {
    let button_1 = await api.get(`config/button_1`)
    button_1 = button_1.data.value
    let button_2 = await api.get(`config/button_2`)
    button_2 = button_2.data.value
    let button_3 = await api.get(`config/button_3`)
    button_3 = button_3.data.value
    let button_4 = await api.get(`config/button_4`)
    button_4 = button_4.data.value

    setButton1(button_1)
    setButton2(button_2)
    setButton3(button_3)
    setButton4(button_4)
  }

  return (
    <div id="dashboard">
      {hp.isPermitted(1) &&
        <Link to="/admin/event1" className="all-unset" ><Button><img className="banner" src={button_1} /></Button></Link>
      }
      {hp.isPermitted(2) &&
        <Link to="/admin/event2" className="all-unset" ><Button><img className="banner" src={button_2} /></Button></Link>
      }
      {hp.isPermitted(3) &&
        <Link to="/admin/event3" className="all-unset" ><Button><img className="banner" src={button_3} /></Button></Link>
      }
      {hp.isPermitted(4) &&
        <Link to="/admin/event4" className="all-unset" ><Button><img className="banner" src={button_4} /></Button></Link>
      }

    </div>
  )
}

export default Page