import { TextareaAutosize } from "@material-ui/core"

const hp = {

    logout: () => {
        window.location.href = "/login"
        localStorage.clear()
    },
    isFull: () => { 
        return localStorage.getItem('is_full')
    },
    getPermission: () => {
        let permissionStr = localStorage.getItem('permission')
        let permissions = permissionStr.split(",")
        return permissions
    },
    isPermitted: (e) => {
        let permissionStr = localStorage.getItem('permission')
        const result = permissionStr.includes(e)
        return result
    }
   
}

export default hp