import { Container, Paper, TableContainer, TextField, Table, TableHead, TableBody, TableCell, TableRow, CircularProgress, Button, Tab } from "@material-ui/core";
import { useEffect, useState } from "react";
import { admin_api } from "../services";
import Session from "./Session";

function Upload({ name, onReload }) {
  const [loading, setLoading] = useState(false)

  const uploadImage = async (files) => {
    let file = files.target.files[0]
    setLoading(true)
    // let data = new FormData()
    // data.append('image', file)
    getBase64(file, async (result) => {
      const uploaded_res = await admin_api.post(`upload-image`, { 'image': result })
      const uploaded = uploaded_res.data
      const update_res = await admin_api.post('config', { name, value: uploaded })
      setLoading(false)
      onReload()
      localStorage.removeItem(name)
    })
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  return (
    <>
      <input
        onChange={uploadImage}
        accept="image/*"
        className="hidden"
        id={`upload-${name}`}
        multiple
        type="file"
      />
      <label htmlFor={`upload-${name}`}>
        {loading ?
          <Button variant="contained" color="primary" component="span" disabled={loading} >
            <CircularProgress color="default" size={22} />
          </Button>
          :
          <Button variant="contained" color="primary" component="span"  >
            Upload
          </Button>
        }
      </label>
    </>
  )
}

function Page() {

  const [config, setConfig] = useState(null)

  useEffect(() => {
    onStart()
  }, [])

  const onStart = async () => {
    const config_res = await admin_api.get('config')
    const config = config_res.data
    let _config = {}
    config.forEach((c) => {
      _config[c.key] = c.value
    })
    setConfig(_config)
  }

  const onChangeDetail = (e, f) => {
    const newConfig = { ...config, [f]: e.target.value }; 
    setConfig(newConfig); 
  };

  const saveDetail = async () => {
    try {
      for (let i = 1; i <= 5; i++) {
        const name = `detail_${i}`;
        const value = config[name];

        const update_res = await admin_api.post('config', {
          name,
          value,
        });

      }

      alert('Configuration details saved successfully!');
    } catch (error) {
      console.error('Error saving configuration details:', error);
      alert('Failed to save some or all configuration details.');
    }
  }


  return (
    <Container maxWidth="lg" className="pt20" >

      {config ?
        <div>
          <Paper className="mb20" >
            <Table>
              <TableHead className="primaryB">
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell style={{ fontSize: 18 }} ><b>Event 1</b></TableCell>
                  <TableCell style={{ fontSize: 18 }}><b>Event 2</b></TableCell>
                  <TableCell style={{ fontSize: 18 }}><b>Event 3</b></TableCell>
                  <TableCell style={{ fontSize: 18 }}><b>Event 4</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow  >
                  <TableCell>Frontend Banner</TableCell>
                  <TableCell>
                    <img src={config.banner_1} width="100%" />
                    <Upload name="banner_1" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.banner_2} width="100%" />
                    <Upload name="banner_2" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.banner_3} width="100%" />
                    <Upload name="banner_3" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.banner_4} width="100%" />
                    <Upload name="banner_4" onReload={onStart} />
                  </TableCell>
                </TableRow>


                <TableRow  >
                  <TableCell>Backend Button</TableCell>
                  <TableCell>
                    <img src={config.button_1} width="100%" />
                    <Upload name="button_1" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.button_2} width="100%" />
                    <Upload name="button_2" onReload={onStart} />

                  </TableCell>
                  <TableCell>
                    <img src={config.button_3} width="100%" />
                    <Upload name="button_3" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.button_4} width="100%" />
                    <Upload name="button_4" onReload={onStart} />
                  </TableCell>
                </TableRow>

                <TableRow  >
                  <TableCell>Backend Menu Items</TableCell>
                  <TableCell>
                    <img src={config.thumbnail_1} width="100%" />
                    <Upload name="thumbnail_1" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.thumbnail_2} width="100%" />
                    <Upload name="thumbnail_2" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.thumbnail_3} width="100%" />
                    <Upload name="thumbnail_3" onReload={onStart} />
                  </TableCell>
                  <TableCell>
                    <img src={config.thumbnail_4} width="100%" />
                    <Upload name="thumbnail_4" onReload={onStart} />
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Paper>

          <Paper >
            <Table>
              <TableHead className="primaryB">
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Column Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Detail 1</TableCell>
                  <TableCell><TextField value={config.detail_1} onChange={(e) => onChangeDetail(e, 'detail_1')} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Detail 2</TableCell>
                  <TableCell><TextField value={config.detail_2} onChange={(e) => onChangeDetail(e, 'detail_2')} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Detail 3</TableCell>
                  <TableCell><TextField value={config.detail_3} onChange={(e) => onChangeDetail(e, 'detail_3')} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Detail 4</TableCell>
                  <TableCell><TextField value={config.detail_4} onChange={(e) => onChangeDetail(e, 'detail_4')} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Detail 5</TableCell>
                  <TableCell><TextField value={config.detail_5} onChange={(e) => onChangeDetail(e, 'detail_5')} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><Button variant="contained" color="primary" onClick={saveDetail} >Save</Button></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
        :

        <div className="loader">
          <CircularProgress />
        </div>

      }

    </Container>
  )
}

export default Page